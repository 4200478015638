<template>
  <ul class="bank-logos-wrapper flex items-center">
    <li
      v-for="(logo, index) in logos"
      :key="logo.name"
      :class="{
        'logo flex items-center justify-center rounded-full': true,
        [logo.name]: logo.name,
      }"
    >
      <Component :is="logo.name" />
    </li>
  </ul>
</template>

<script>
import { IconLloyds, IconBarclays, IconHsbc, IconPlus } from '@galileo/assets/icons/vue'

export default {
  name: 'SendMoneyBankLogos',
  components: {
    IconLloyds,
    IconBarclays,
    IconHsbc,
    IconPlus,
  },
  setup(props, { emit }) {
    const logos = [
      {
        name: 'icon-lloyds',
      },
      {
        name: 'icon-barclays',
      },
      {
        name: 'icon-hsbc',
      },
      {
        name: 'icon-plus',
      },
    ]

    return {
      logos,
    }
  },
}
</script>

<style scoped>
.bank-logos-wrapper {
  margin-top: 6px;
  .logo {
    @apply relative w-8 h-8 bg-gray-200;
    border: 1.5px solid #fff;
    svg {
      @apply w-5 h-5;
    }
    &.icon-lloyds {
      background-color: #12b67a;
    }
    &.icon-barclays {
      background-color: #e7e7e7;
      left: -7px;
    }
    &.icon-hsbc {
      left: -14px;
    }
    &.icon-plus {
      svg {
        @apply w-3 h-3;
      }
      left: -21px;
    }
  }
}
</style>
