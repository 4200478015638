<template>
  <div>
    <AppList v-if="currentPaymentMethod">
      <AppListItemCaption class="xe-caption xs:pt-4 sm:pt-0">
        {{ $t('ComponentAddPaymentMethodType.SelectedPaymentMethodText').value }}
      </AppListItemCaption>
      <AppListItem
        @itemClick="$emit('select', currentPaymentMethod)"
        :class="{
          'bank-logos-visible': showBankLogos,
        }"
      >
        <template #figure>
          <AppFigure class="xe-figure" theme="gray">
            <Component class="payment-method-icon" :is="paymentIcon(currentPaymentMethod.value)" />
          </AppFigure>
        </template>
        <template #left>
          <AppListItemTitle v-if="currentPaymentMethod.text === 'Interac'" class="interac-wrapper">
            <p>
              {{ currentPaymentMethod.text }}
            </p>
            <IconInterac />
          </AppListItemTitle>
          <AppListItemTitle v-else>
            {{ currentPaymentMethod.text }}
          </AppListItemTitle>
          <AppListItemCaption>
            {{ getFee(currentPaymentMethod) }}
            <BankLogos v-if="showBankLogos" />
          </AppListItemCaption>
        </template>
      </AppListItem>
    </AppList>

    <AppButton theme="primary" @click="$emit('select', currentPaymentMethod)">
      {{ $t('ComponentAddPaymentMethodModal.ButtonContinue').value }}
    </AppButton>
    <AppListItemCaption
      v-if="availablePaymentMethods && availablePaymentMethods.length > 0"
      class="xe-caption mt-4"
    >
      {{ $t('ComponentAddPaymentMethodType.AvailablePaymentMethodsText').value }}
    </AppListItemCaption>
    <AppList
      v-for="method in availablePaymentMethods"
      :key="method.text"
      :border-top="method != availablePaymentMethods[0]"
    >
      <AppListItem
        :class="{
          'payment-method-item--is-disabled': !method.isAvailable,
          'bank-logos-visible': method.value === 'OpenBanking',
        }"
        @itemClick="onItemClick(method)"
      >
        <template #figure>
          <AppFigure class="xe-figure" theme="gray">
            <Component class="payment-method-icon" :is="paymentIcon(method.value)" />
          </AppFigure>
        </template>
        <template #left>
          <PaymentMethodsListItemCaption :payment-method="method" />
        </template>
        <template #action>
          <AppIcon name=" ">
            <IconChevronRight />
          </AppIcon>
        </template>
      </AppListItem>
    </AppList>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import PaymentMethodsListItemCaption from '@galileo/components/Views/PaymentMethod/PaymentMethodsListItemCaption'
import BankLogos from '@galileo/components/Views/SendMoney/SendMoneyBankLogos'
import { useBankLogos } from '@galileo/composables/useBankLogos'

import {
  AppList,
  AppListItem,
  AppListItemTitle,
  AppListItemCaption,
  AppFigure,
  AppIcon,
  AppButton,
} from '@oen.web.vue2/ui'

import { IconChevronRight, IconMoneyBag } from '@oen.web.vue2/icons'

import {
  IconVolt,
  IconPayTo,
  IconCreditCard,
  IconBank,
  IconACH,
  IconEFT,
  IconInterac,
} from '@galileo/assets/icons/vue'

import { PaymentMethod } from '@galileo/models/Transaction/app'

import { useI18nStore, useSendMoneyStore, useAuthStore, useAnalyticsStore } from '@galileo/stores'
import useOpenBanking from '@galileo/composables/useOpenBanking'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

export default {
  name: 'AddPaymentMethodType',
  components: {
    AppList,
    AppListItem,
    AppListItemTitle,
    AppListItemCaption,
    AppFigure,
    AppIcon,
    IconChevronRight,
    IconCreditCard,
    IconMoneyBag,
    IconACH,
    IconEFT,
    IconBank,
    PaymentMethodsListItemCaption,
    IconVolt,
    IconPayTo,
    IconInterac,
    AppButton,
    BankLogos,
  },
  props: {
    paymentMethodType: {
      type: String,
      default: null,
    },
    paymentMethodsOption: {
      type: Array,
      default: null,
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const sendMoneyStore = useSendMoneyStore()
    const analyticsStore = useAnalyticsStore()
    const { shouldUsePayTo } = useOpenBanking()
    const currencyFrom = computed(() => sendMoneyStore.getCurrencyFrom)
    let paymentMethod = {}

    const currentPaymentMethod = computed(() => {
      if (props.paymentMethodsOption && props.paymentMethodType) {
        paymentMethod = props.paymentMethodsOption.find(
          (method) => method.value === props.paymentMethodType
        )

        if (paymentMethod.value === PaymentMethod.OpenBanking.enumName && shouldUsePayTo.value) {
          paymentMethod.text = $t('PageSendMoneyAmount.PayToOpenBankingDropdownText').value
        }

        return paymentMethod
      }
    })

    const showBankLogos = computed(() => {
      return useBankLogos(currentPaymentMethod.value.value)
    })

    const availablePaymentMethods = computed(() => {
      return props.paymentMethodsOption.filter((method) => method.value !== props.paymentMethodType)
    })

    const senderCountry = authStore.userProfile?.country

    const paymentIcon = (paymentMethod) => {
      if (paymentMethod === PaymentMethod.BankAccount.enumName && senderCountry === 'US') {
        return 'icon-ACH'
      } else if (paymentMethod === PaymentMethod.BankAccount.enumName && senderCountry === 'CA') {
        return 'icon-EFT'
      } else if (paymentMethod === PaymentMethod.OpenBanking.enumName && shouldUsePayTo.value) {
        return 'icon-pay-to'
      } else if (paymentMethod === PaymentMethod.OpenBanking.enumName) {
        return 'icon-volt'
      } else if (
        paymentMethod === PaymentMethod.CreditCard.enumName ||
        paymentMethod === PaymentMethod.DebitCard.enumName
      ) {
        return 'icon-credit-card'
      } else {
        return 'icon-bank'
      }
    }

    const getFee = (method) => {
      const totalFee = method.quote.paymentMethodFee + method.quote.transferFee
      return `+${totalFee.toFixed(2)} ${currencyFrom.value} - ${method.quote.time}`
    }

    const onSwitchToDifferentPaymentMethod = (newPaymentMethod) => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.PAYMENT_METHOD_CHANGED,
        traits: {
          availableMethods: [...new Set(availablePaymentMethods.value.map((pm) => pm['value']))],
          newMethod: newPaymentMethod.value,
          oldMethod: currentPaymentMethod.value,
          location: SEGMENT_LOCATIONS.SEND_MONEY,
        },
      })
    }

    const onItemClick = (method) => {
      if (method.isAvailable) {
        emit('select', method)
        onSwitchToDifferentPaymentMethod(method)
      }
    }

    return {
      $t,
      currencyFrom,
      currentPaymentMethod,
      availablePaymentMethods,
      onSwitchToDifferentPaymentMethod,
      paymentIcon,
      getFee,
      onItemClick,
      showBankLogos,
    }
  },
}
</script>

<style scoped>
.payment-method-icon {
  @apply w-6 h-6;
}

::v-deep .list {
  @apply mt-0;
}

::v-deep .list-item {
  @apply border-none;

  &.bank-logos-visible {
    .list-item-content-flex-container {
      @apply flex items-start;
    }
  }
}

.list-item-title {
  @apply text-base font-medium;
}

.list-item-caption {
  @apply font-medium text-xs;
}

.xe-caption {
  @apply font-medium text-base text-grayblue-300 !important;
  letter-spacing: 1px;
}

.payment-method-item--is-disabled {
  @apply cursor-not-allowed pointer-events-none;
  .payment-method-item-title-container {
    @apply text-tertiary-text;
  }
  .payment-method-item-caption {
    @apply text-red-text;
  }
  .payment-method-item-caption--gray {
    @apply text-tertiary-text;
  }
  ::v-deep .list-item-action {
    @apply text-tertiary-text;
  }
}

::v-deep .list-item-caption {
  @apply text-xs font-normal text-tertiary-text;
}
::v-deep .list-item-button {
  .interac-wrapper {
    @apply flex items-center;
    svg {
      @apply ml-2;
    }
  }
}
</style>
