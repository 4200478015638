<template>
  <AppCard>
    <template #header>
      <XeStepperCardHeader
        :title="bankProviderTitle"
        :emitBack="true"
        analytics-name="xe-send-money-payment"
        @back="$emit('backToPaymentType', $event)"
      />
    </template>

    <AppAlert theme="blue" class="mb-6">
      <p class="flex">
        <IconExclamationMark class="mr-2 w-12" />
        {{ $t('SendMoneyPaymentBankTransfer.BankDetailsAlert').value }}
        <!-- Check you have the correct bank details before making your payment. We’ll email these after
        your transfer is confirmed. -->
      </p>
    </AppAlert>

    <p
      class="bank-transfer-paragraph"
      v-html="
        $t('SendMoneyPaymentBankTransfer.BankProviderBankTrasnferParagraph', {
          currencyFrom: currencyFrom,
          currencyTo: currencyTo,
        }).value
      "
    />

    <!-- v-model="form.bankProvider" -->
    <AppInputDropdown
      v-model="form.bankProvider"
      class="label-fix"
      :placeholder="$t('SendMoneyPaymentBankTransfer.DropdownPlaceholderText').value"
      :label="$t('SendMoneyPaymentBankTransfer.TransferToOurAccountLabel').value"
      :options="bankProviders"
      :helper-text="
        $t('SendMoneyPaymentBankTransfer.AccountInfoHelp', {
          currencyFrom: currencyFrom,
        }).value
      "
      analytics-name="xe-send-money-payment-bank-provider"
      selected-display-field="name"
      selected-value-field="id"
    />

    <template #footer>
      <AppCardFooter>
        <AppButton
          class="bankTransfer-footer"
          :disabled="validation.$anyInvalid"
          analytics-name="xe-send-money-payment-continue"
          @click="submit"
        >
          {{ $t('SendMoneyPaymentBankTransfer.ContinueButtonText').value }}
        </AppButton>
      </AppCardFooter>
    </template>
  </AppCard>
</template>

<script>
import { defineComponent, onBeforeMount, ref, toRef, reactive } from '@vue/composition-api'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import { useValidation } from 'vue-composable'

import { AppCard, AppInputDropdown, AppCardFooter, AppButton, AppAlert } from '@oen.web.vue2/ui'

import { IconExclamationMark } from '@galileo/assets/icons/vue'
import { STEPS } from '@galileo/constants/sendMoneyFlow.const'

import {
  useI18nStore,
  usePaymentsStore,
  useAppStore,
  useAuthStore,
  useSendMoneyStore,
  useAnalyticsStore,
} from '@galileo/stores'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'

export default defineComponent({
  components: {
    AppAlert,
    XeStepperCardHeader,
    AppCard,
    AppInputDropdown,
    AppCardFooter,
    AppButton,
    IconExclamationMark,
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const analyticsStore = useAnalyticsStore()
    const paymentsStore = usePaymentsStore()
    const sendMoneyStore = useSendMoneyStore()

    const currencyFrom = sendMoneyStore.getCurrencyFrom
    const currencyTo = sendMoneyStore.form.currencyTo
    const user = authStore.userProfile
    const bankProviders = ref()
    const appStore = useAppStore()
    const valueRequired = getValueRequiredValidation()
    const form = reactive({
      bankProvider: null,
    })
    const bankProviderTitle = ref($t('SendMoneyPaymentBankTransfer.Header').value)

    onBeforeMount(async () => {
      const isPaymentMethodSelected = sendMoneyStore.form.paymentMethodId

      if (user.country === 'US') {
        bankProviderTitle.value = $t('SendMoneyPaymentBankTransfer.HeaderUS').value
      }

      bankProviders.value = await paymentsStore.getBankProviders(currencyFrom)
      const previousRoute = appStore.getCurrentRoute.from.name

      if (isPaymentMethodSelected && previousRoute === STEPS.SEND_MONEY_SUMMARY) {
        form.bankProvider = isPaymentMethodSelected
      }

      analyticsStore.track({
        event: SEGMENT_EVENTS.BANK_ACCOUNT_SELECTION_STARTED,
        traits: {
          email: user.customer.email,
          numberOfAccounts: bankProviders.value.length,
          sendCurrency: currencyFrom,
        },
      })
    })

    const validation = useValidation({
      bankProvider: {
        $value: toRef(form, 'bankProvider'),
        valueRequired,
      },
    })

    const submit = () => {
      emit('submit', form.bankProvider)

      const selectedBankProvider = bankProviders.value.find((el) => el.id == form.bankProvider)

      analyticsStore.track({
        event: SEGMENT_EVENTS.BANK_ACCOUNT_SELECTION_COMPLETED,
        traits: {
          email: user.customer.email,
          bankAccountChosen: selectedBankProvider.name,
        },
      })
    }

    return {
      bankProviders,
      validation,
      currencyFrom,
      currencyTo,
      $t,
      submit,
      form,
      bankProviderTitle,
    }
  },
})
</script>

<style scoped>
.bank-transfer-paragraph {
  @apply text-secondary-text text-left text-base mb-8;
}

.bankTransfer-footer {
  @apply w-full !important;
}

::v-deep .label-fix label {
  @apply text-base font-medium !important;
}
</style>
