<template>
  <div>
    <!-- Loading -->
    <AppSpinnerBig :loading="loading"></AppSpinnerBig>

    <!-- Payment methods -->
    <template v-if="!selectedPaymentMethodType && !loading">
      <SelectPaymentMethodType @selectPaymentMethodType="selectPaymentMethodType($event)" />
    </template>

    <template v-if="selectedPaymentMethodType && !showBankProvider && !loading">
      <PaymentMethodsList
        :key="paymentMethodType"
        :type="paymentMethodType"
        is-send-money
        @backToPaymentType="backToPaymentType"
        @addPaymentMethod="addPaymentMethodOverride"
        @selectPaymentMethod="selectPaymentMethod"
      />
    </template>

    <template v-if="selectedPaymentMethodType && showBankProvider && !loading">
      <SelectBankProvider @backToPaymentType="backToPaymentType" @submit="submitBankProvider" />
    </template>
    <!-- end of choose pamnet method -->

    <!-- Security code dialog -->
    <CardCVVInputModal
      v-if="showSecurityCodeDialog"
      v-model="showSecurityCodeDialog"
      :title="selectedPaymentMethodTitle"
      :masked-account-number="selectedPaymentMethodMaskedAccountNumber"
      @submit="submitSecurityCode"
    />

    <WireTransferInstructionsModal
      v-if="showWireTransferWarning"
      @close="closeWireTransferInfoModal"
    />

    <InteracTransferInstructionsModal
      v-if="showInteracTransferWarning"
      @close="closeInteracTransferInfoModal"
    />
    <PendingApprovalModal v-model="showPendingApprovalModal" />
    <!-- Renders AppModal, children routes to be configured in vue router -->

    <UpdateLegacyCardModal
      @close="closeUpdateLegacyCardModal"
      @update="startUpdateLegacyCardModal"
      v-model="showUpdateLegacyCardRequiredModal"
    />

    <RedirectToQuickTransfer v-model="isRedirectToQuickTransferModalOpen" />

    <ModalRouterView />
  </div>
</template>

<script>
import { computed, onBeforeMount, ref, reactive, watch } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'
import { useAddPaymentMethods } from '@galileo/composables/usePaymentHelpers'
import CardCVVInputModal from '@galileo/components/Views/PaymentMethod/CardCVVInputModal'
import ModalRouterView from '@galileo/views/ModalRouterView'
import PendingApprovalModal from '@galileo/components/Views/SendMoney/Amount/PendingApprovalModal.vue'

import {
  SEGMENT_EVENTS,
  SEGMENT_LOCATIONS,
  SEGMENT_PAYMENT_METHOD_TYPES,
} from '@galileo/constants/segmentAnalytics'

import { PaymentMethod } from '@galileo/models/Transaction/app'
import WireTransferInstructionsModal from '@galileo/components/Views/SendMoney/Modals/WireTransferInstructionsModal'
import InteracTransferInstructionsModal from '@galileo/components/Views/SendMoney/Modals/InteracTransferInstructionsModal'

import SelectPaymentMethodType from '@galileo/components/Views/SendMoney/Payments/SelectPaymentMethodType'
import PaymentMethodsList from '@galileo/components/Views/SendMoney/Payments/PaymentMethodsList'
import SelectBankProvider from '@galileo/components/Views/SendMoney/Payments/SelectBankProvider'

import useCardHelper from '@galileo/composables/useCardHelper'

import { useMediaQuery, AppSpinnerBig } from '@oen.web.vue2/ui'
import { STEPS, PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import { storeToRefs } from 'pinia'
import { ACCOUNT_KYC_STATUS } from '@galileo/constants/accountKycStatus.const.ts'
import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'
import { CARD_TYPES_MAPPINGS } from '@galileo/constants/cardPayments.const'
import RedirectToQuickTransfer from '@galileo/components/Views/SendMoney/RedirectToQuickTransfer'

import {
  useI18nStore,
  useSendMoneyStore,
  useAppStore,
  useAnalyticsStore,
  usePaymentsStore,
  useAuthStore,
  useCardAccountStore,
  useSessionStore,
} from '@galileo/stores'

import useOpenBanking from '@galileo/composables/useOpenBanking'
import UpdateLegacyCardModal from '@galileo/components/Views/PaymentMethod/CardPayments/UpdateLegacyCardModal.vue'
import { ACCOUNT_TYPE } from '@galileo/constants/accountType.const'

export default {
  name: 'SendMoneyPayment',
  components: {
    AppSpinnerBig,
    CardCVVInputModal,
    ModalRouterView,
    WireTransferInstructionsModal,
    SelectPaymentMethodType,
    PaymentMethodsList,
    SelectBankProvider,
    PendingApprovalModal,
    InteracTransferInstructionsModal,
    HelpDeskProperties,
    UpdateLegacyCardModal,
    RedirectToQuickTransfer,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const router = useRouter()
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()
    const paymentsStore = usePaymentsStore()
    const sessionStore = useSessionStore()
    const cardAccount = useCardAccountStore()
    const cardHelper = useCardHelper($t)
    const isRedirectToQuickTransferModalOpen = ref(false)

    const showWireTransferWarning = ref(false)
    const showInteracTransferWarning = ref(false)
    const showUpdateLegacyCardRequiredModal = ref(false)

    const paymentMethodsList = computed(() => sendMoneyStore.form.paymentMethods)
    const interacEmail = HelpDesk[HelpDeskProperties.INTERAC_EMAIL]

    const paymentGateway = authStore.userProfile.customer.cardPaymentGateway

    const { addPaymentMethod } = useAddPaymentMethods()

    const country = authStore.userProfile.country

    const { shouldUsePayTo } = useOpenBanking()
    analyticsStore.setPaymentLocation(SEGMENT_LOCATIONS.SEND_MONEY)

    // Tell the store which step we are on
    sendMoneyStore.setActiveStep(router.currentRoute.path)

    // Return the step data to the component
    const activeStep = sendMoneyStore.activeStep

    const loading = ref(true)

    const paymentMethodType = computed(() => sendMoneyStore.form.paymentMethod)

    const form = reactive({
      bankProvider: null,
    })
    const showBankProvider = ref(false)

    const bankProviders = ref([])

    onBeforeMount(async () => {
      // TODO - Add error handling and replace with usePromiseLazy
      const currency = sendMoneyStore.getCurrencyFrom
      await paymentsStore.getPaymentMethods(currency)

      bankProviders.value = await paymentsStore.getBankProviders(currency)

      if (bankProviders.value && bankProviders.value.length > 0) {
        form.bankProvider = bankProviders.value[0].id
      }

      if (sendMoneyStore.getCardPaymentAvailable) {
        //get card settings
        await cardHelper.retrieveCardSettings(country)
      }

      displayPaymentMethodTypeSelection()

      analyticsStore.track({
        event: SEGMENT_EVENTS.PAYMENT_METHODS_ACCESSED,
        traits: {
          availableMethods: [...new Set(paymentMethodsList.value.map((pm) => pm['value']))],
          currentMethod: paymentMethodType.value,
          location: SEGMENT_LOCATIONS.SEND_MONEY,
        },
      })

      loading.value = false
    })

    const displayPaymentMethodTypeSelection = () => {
      const previousSelectedPaymentMethod = paymentMethodsList.value.find(
        (method) => method.value === paymentMethodType.value
      )

      const previousRoute = appStore.getCurrentRoute.from.name

      if (previousRoute === STEPS.SEND_MONEY_SUMMARY) {
        //if bank transfer and we have only one bank provider then we go to payment methods type screen
        const isBankTransferWithOneBankProvider =
          previousSelectedPaymentMethod.value === PAYMENT_METHODS.BANK_TRANSFER &&
          bankProviders.value.length === 1
        //if we added previosly a card but we go baack to payments we dont have it saved
        //hence we will show payment methods type screen
        const isCardPaymentWithNoSavedCards =
          (previousSelectedPaymentMethod.value === PAYMENT_METHODS.CREDIT_CARD ||
            previousSelectedPaymentMethod.value === PAYMENT_METHODS.DEBIT_CARD) &&
          paymentMethods.value.length === 0

        if (isBankTransferWithOneBankProvider || isCardPaymentWithNoSavedCards) {
          selectedPaymentMethodType.value = false
        }
      }

      //if it's not bank transfer adn we have selected payment we preselect the payment method type
      if (
        previousSelectedPaymentMethod.value !== PAYMENT_METHODS.BANK_TRANSFER &&
        selectedPaymentMethodType.value
      ) {
        selectPaymentMethodType(previousSelectedPaymentMethod)
      } else if (selectedPaymentMethodType.value) {
        //if it's a bank transfer we show the bank provider page
        showBankProvider.value = true
        showWireTransferWarning.value = true
      }
    }

    const nextRoute = {
      name: STEPS.SEND_MONEY_SUMMARY,
    }

    const submitSecurityCode = (securityCode) => {
      sendMoneyStore.form.cardSecurityCode = securityCode

      analyticsStore.track({
        event: SEGMENT_EVENTS.CVV_ENTERED,
        traits: {
          paymentType: paymentMethodType.value,
          location: SEGMENT_LOCATIONS.SEND_MONEY,
          brand: sessionStore.brand,
        },
      })

      router.push(nextRoute)
    }

    const showPendingApprovalModal = ref(false)

    const user = authStore.user
    const { isCorporateAccount } = storeToRefs(authStore)

    const handleNotApprovedCorpAccount = () => {
      showPendingApprovalModal.value = true
      analyticsStore.track({
        event: SEGMENT_EVENTS.ACCOUNT_NOT_APPROVED,
        traits: {
          location: SEGMENT_LOCATIONS.SEND_MONEY,
        },
      })
    }

    const submitBankProvider = (provider = null) => {
      if (provider) {
        form.bankProvider = provider
      }
      const bankProvider = bankProviders.value.find((bank) => bank.id === form.bankProvider)
      sendMoneyStore.form.paymentMethodId = form.bankProvider

      sendMoneyStore.setPaymentMethodName({
        paymentMethodTitle:
          country === 'US'
            ? $t('PageSendMoneyPayment.WireTransfer').value
            : $t('PageSendMoneyPayment.BankTransfer').value,
        paymentMethodCaption: bankProvider.bankName,
        paymentMethodText: bankProvider.bankName,
      })

      analyticsStore.track({
        event: SEGMENT_EVENTS.PAYMENT_METHOD_SELECTED,
        traits: {
          paymentType: SEGMENT_PAYMENT_METHOD_TYPES.STAGED,
        },
      })
      if (
        isCorporateAccount.value &&
        user.customer.accountKycStatus === ACCOUNT_KYC_STATUS.NOT_APPROVED
      ) {
        handleNotApprovedCorpAccount()
      } else {
        router.push(nextRoute)
      }
    }

    const showSecurityCodeDialog = ref(false)
    const selectedPaymentMethodType = ref(false)
    const selectedPaymentMethodTitle = ref('')
    const selectedPaymentMethodMaskedAccountNumber = ref('')

    watch(
      () => sendMoneyStore.isPaymentMethodTypeSelected,
      (value) => {
        if (!value && selectedPaymentMethodType.value) {
          selectedPaymentMethodType.value = false
        }
      }
    )

    const closeWireTransferInfoModal = (aknowledge = true) => {
      showWireTransferWarning.value = false
      if (!aknowledge) {
        selectedPaymentMethodType.value = false
        showBankProvider.value = false
      } else {
        if (bankProviders.value && bankProviders.value.length === 1) {
          submitBankProvider()
        }
      }
    }

    const closeInteracTransferInfoModal = (aknowledge = true) => {
      showInteracTransferWarning.value = false
      if (!aknowledge) {
        selectedPaymentMethodType.value = false
      } else {
        sendMoneyStore.setPaymentMethodName({
          paymentMethodTitle: PaymentMethod.Interac.text,
          paymentMethodCaption: $t('InteracTransfer.Caption', { interacEmail }).value,
          paymentMethodText: PaymentMethod.Interac.text,
        })

        sendMoneyStore.form.paymentMethodId = form.bankProvider
        router.push(nextRoute)
      }
    }

    watch(selectedPaymentMethodType, (value) => {
      if (!value) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYMENT_METHOD_PICKER_ACCESSED,
          traits: {
            location: SEGMENT_LOCATIONS.SEND_MONEY,
          },
        })
      }
    })

    const paymentMethodTitle = ref('')

    const selectPaymentMethodType = async (methodType) => {
      if (methodType.value === PAYMENT_METHODS.FUNDS_ON_BALANCE) {
        isRedirectToQuickTransferModalOpen.value = true
        return
      }
      await sendMoneyStore.setPaymentMethodAction(methodType.value)
      sendMoneyStore.setPaymentMethodType(methodType)
      paymentMethodTitle.value = methodType.text
      if (!paymentMethodTitle.value) {
        paymentMethodTitle.value = $t('PageSendMoneyAddPaymentMethodType.Header').value
      }

      if (paymentMethodType.value === PAYMENT_METHODS.BANK_TRANSFER) {
        showWireTransferWarning.value = true
        if (bankProviders.value && bankProviders.value.length === 1) {
          return
        }
        showBankProvider.value = true
      } else if (paymentMethodType.value === PAYMENT_METHODS.OPEN_BANKING) {
        showBankProvider.value = false
        if (
          isCorporateAccount.value &&
          user.customer.accountKycStatus === ACCOUNT_KYC_STATUS.NOT_APPROVED
        ) {
          handleNotApprovedCorpAccount()
          return
        }

        sendMoneyStore.setPaymentMethodName({
          paymentMethodTitle: shouldUsePayTo.value
            ? $t('PageSendMoneyAmount.PayToOpenBankingDropdownText').value
            : $t('SendMoneyAmount.OpenBankingTitle').value,
          paymentMethodCaption: shouldUsePayTo.value
            ? $t('SendMoneyPayment.OpenBankingDropdownDescriptionPayTo').value // 'Pay instantly through your bank'
            : $t('SendMoneyPayment.OpenBankingDropdownDescriptionVolt').value, // 'Authorize payment from your bank',
          paymentMethodText: shouldUsePayTo.value
            ? $t('SendMoneyPayment.OpenBankingDropdownDescriptionPayTo').value // 'Pay instantly through your bank'
            : $t('SendMoneyPayment.OpenBankingDropdownDescriptionVolt').value, // 'Authorize payment from your bank',
        })

        selectedPaymentMethodType.value = methodType.value

        let paymentMethod = paymentMethodsList.value.find((pm) => pm.value === methodType.value)
        if (paymentMethod) {
          selectedPaymentMethodType.value = paymentMethod.text
        }
        analyticsStore.track({
          event: SEGMENT_EVENTS.NEW_PAYMENT_METHOD_STARTED,
          traits: {
            paymentType: paymentMethodType.value,
          },
        })

        router.push(nextRoute)
      } else if (paymentMethodType.value === PAYMENT_METHODS.INTERAC) {
        showInteracTransferWarning.value = true
        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYMENT_METHOD_SELECTED,
          traits: {
            paymentType: SEGMENT_PAYMENT_METHOD_TYPES.INTERAC,
          },
        })
      } else {
        //DIRECT DEBIT

        showBankProvider.value = false
        //reset card settings
        cardAccount.resetCardSettings()

        // if there is no paymenth method available, then
        // we just redirect to create a new payment method instead of showing the
        // extra step of "oh no there are no payment methods present."
        if (!paymentMethods.value.length) {
          addPaymentMethodOverride()

          //we finish the flow we wont direct the user
          //to show him the empty list
          return
        }
      }

      selectedPaymentMethodType.value = methodType.value

      let paymentMethod = paymentMethodsList.value.find((pm) => pm.value === methodType.value)
      if (paymentMethod) {
        selectedPaymentMethodType.value = paymentMethod.text
      }
    }

    const paymentMethods = computed(() => {
      return paymentsStore.getPaymentMethodsByType(paymentMethodType.value, true)
    })

    const selectPaymentMethod = async (selectedPaymentMethod) => {
      cardAccount.resetForm()
      const { id, maskedAccountNumber, title } = selectedPaymentMethod

      if (
        paymentMethodType.value === PAYMENT_METHODS.CREDIT_CARD ||
        paymentMethodType.value === PAYMENT_METHODS.DEBIT_CARD
      ) {
        if (selectedPaymentMethod.cardState === 'Invalid') {
          showUpdateLegacyCardRequiredModal.value = true
          cardAccount.selectedCardForUpdate = selectedPaymentMethod

          analyticsStore.track({
            event: SEGMENT_EVENTS.UPDATE_CARD_PROMPT,
            traits: {
              paymentType: sendMoneyStore.form.paymentMethod,
              paymentProvider: authStore.userProfile.customer.cardPaymentGateway,
              location: SEGMENT_LOCATIONS.SEND_MONEY,
            },
          })

          return
        }

        await cardAccount.fetchCardSettings() // do we need this?
        cardAccount.setCardNumberForIdentification(selectedPaymentMethod.panFirstDigits)

        sendMoneyStore.setPaymentMethodName({
          paymentMethodTitle: cardHelper.createCardTitle(selectedPaymentMethod, title),
          paymentMethodCaption: '',
          paymentMethodText: title,
          paymentMethodNumber: maskedAccountNumber,
        })
        paymentsStore.setSelectedPaymentMethod(selectedPaymentMethod)
        selectedPaymentMethodTitle.value = title
        selectedPaymentMethodMaskedAccountNumber.value = maskedAccountNumber
        showSecurityCodeDialog.value = true

        // if we are using cybersource we need the paymentMethodId to be set as the card
        if (useCardAccountStore().useCybersource) {
          sendMoneyStore.form.paymentMethodId = id
        }

        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYMENT_METHOD_SELECTED,
          traits: {
            paymentType: paymentMethodType.value,
            location: SEGMENT_LOCATIONS.SEND_MONEY,
            newCard: false,
            paymentProvider: paymentGateway,
            cardBillingCountry: selectedPaymentMethod.billingCountry,
            cardBrand: CARD_TYPES_MAPPINGS[selectedPaymentMethod.type],
            brand: sessionStore.brand,
          },
        })
      } else {
        sendMoneyStore.setPaymentMethodName({
          paymentMethodTitle: title,
          paymentMethodCaption: maskedAccountNumber,
          paymentMethodText: title,
          paymentMethodNumber: maskedAccountNumber,
        })
        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYMENT_METHOD_SELECTED,
          traits: {
            paymentType: SEGMENT_PAYMENT_METHOD_TYPES.BANK_ACCOUNT,
          },
        })

        sendMoneyStore.form.paymentMethodId = id

        if (
          isCorporateAccount.value &&
          user.customer.accountKycStatus === ACCOUNT_KYC_STATUS.NOT_APPROVED
        ) {
          handleNotApprovedCorpAccount()
        } else {
          router.push(nextRoute)
        }
      }
    }
    const backToPaymentType = () => {
      selectedPaymentMethodType.value = null
      showBankProvider.value = false
    }

    const closeUpdateLegacyCardModal = () => {
      cardAccount.selectedCardForUpdate = null
      showUpdateLegacyCardRequiredModal.value = false
    }

    const startUpdateLegacyCardModal = () => {
      showUpdateLegacyCardRequiredModal.value = false
      addPaymentMethod()
    }

    const addPaymentMethodOverride = () => {
      const limitReached = cardHelper.cardLimitReached.value

      if (
        (paymentMethodType.value === PAYMENT_METHODS.CREDIT_CARD ||
          paymentMethodType.value === PAYMENT_METHODS.DEBIT_CARD) &&
        limitReached
      ) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYMENT_METHOD_ADD_ATTEMPT_LIMIT_REACHED,
          traits: {
            paymentMethod: paymentMethodType,
            location: SEGMENT_LOCATIONS.SEND_MONEY,
            accountType: isCorporateAccount ? ACCOUNT_TYPE.CORPORATE : ACCOUNT_TYPE.CONSUMER,
            email: authStore.user.customer.email,
          },
        })
        router.replace('/send-money/payment/card-limit-reached')
      } else {
        cardAccount.selectedCardForUpdate = null
        addPaymentMethod()
      }
    }

    return {
      mq: useMediaQuery(),
      activeStep,
      loading,
      paymentMethods,
      selectPaymentMethod,
      addPaymentMethod,
      addPaymentMethodOverride,
      showSecurityCodeDialog,
      submitSecurityCode,
      showBankProvider,
      bankProviders,
      submitBankProvider,
      $t,
      paymentMethodType,
      selectedPaymentMethodType,
      selectPaymentMethodType,
      backToPaymentType,
      showWireTransferWarning,
      closeWireTransferInfoModal,
      selectedPaymentMethodTitle,
      selectedPaymentMethodMaskedAccountNumber,
      showPendingApprovalModal,
      showUpdateLegacyCardRequiredModal,
      closeUpdateLegacyCardModal,
      startUpdateLegacyCardModal,
      closeInteracTransferInfoModal,
      showInteracTransferWarning,
      interacEmail,
      isRedirectToQuickTransferModalOpen,
    }
  },
}
</script>

<style scoped>
.add-payment-method-title {
  @apply leading-5 text-base font-medium text-blue;
}

.saved-payment-methods-title {
  @apply font-semibold;
  @apply uppercase leading-4 text-gray-text tracking-extrawide;
  @apply self-center;
  @apply mb-1 mt-5;
  font-size: 10px;
}

.card-header {
  @apply flex justify-between content-center;
  ::v-deep .card-header-title {
    @apply text-lg leading-6 h-auto;
  }
  @screen sm {
    @apply text-lg leading-6 h-auto;
    @apply px-12 py-12;
  }
}
</style>
