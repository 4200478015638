<template>
  <div>
    <!-- No payment methods -->
    <NoPaymentMethods
      v-if="!paymentMethodsExists"
      is-send-money
      @addPaymentMethod="$emit('addPaymentMethod')"
      @back="$emit('backToPaymentType')"
    />

    <AppCard v-if="paymentMethodsExists">
      <template #header>
        <XeStepperCardHeader
          :title="paymentMethodTitle"
          :emitBack="true"
          analytics-name="xe-send-money-add-payment-method"
          @back="$emit('backToPaymentType')"
        />
      </template>

      <!-- 'Add Payment Method' button -->
      <AppList class="payment-methods-list">
        <XeAddListItem :select="selectCard" :text="buttonTitle" />
      </AppList>

      <div v-if="hasSavedPaymentMethods" class="saved-payment-methods-title">
        {{ $t('PageSendMoneyAddPaymentMethodType.SavedPaymentMethodsText').value }}
      </div>

      <PaymentMethods
        :payment-methods="paymentMethods"
        is-send-money
        :is-override-action-menu="true"
        @select="$emit('selectPaymentMethod', $event)"
      />
    </AppCard>

    <!-- Limit reached modal  -->
    <LimitReachedModal v-model="isLimitReached" />
  </div>
</template>

<script>
import { defineComponent, computed, ref, onBeforeMount } from '@vue/composition-api'
import NoPaymentMethods from '@galileo/components/Views/PaymentMethod/NoPaymentMethods'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'
import XeAddListItem from '@galileo/components/XeAddListItem/XeAddListItem'
import PaymentMethods from '@galileo/components/Views/PaymentMethod/PaymentMethods'

import { AppCard, AppList } from '@oen.web.vue2/ui'
import {
  useI18nStore,
  usePaymentsStore,
  useSendMoneyStore,
  useAnalyticsStore,
  useAuthStore,
} from '@galileo/stores'
import { SEGMENT_LOCATIONS, SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import LimitReachedModal from '@galileo/components/Views/PaymentMethod/LimitReachedModal.vue'
import useCardHelper from '@galileo/composables/useCardHelper/index.ts'
import { useRouter } from '@galileo/composables/useRouter'
import { ACCOUNT_TYPE } from '@galileo/constants/accountType.const'

export default defineComponent({
  name: 'PaymentMethodsList',
  components: {
    NoPaymentMethods,
    AppCard,
    XeStepperCardHeader,
    AppList,
    XeAddListItem,
    PaymentMethods,
    LimitReachedModal,
  },
  emits: ['selectPaymentMethod', 'addPaymentMethod', 'backToPaymentType'],
  props: {
    type: {
      type: String,
      required: true,
    },
    isSendMoney: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()
    const analyticsStore = useAnalyticsStore()
    const cardHelper = useCardHelper()
    const router = useRouter()
    const authStore = useAuthStore()

    const paymentsStore = usePaymentsStore()

    const paymentMethods = computed(() => paymentsStore.getPaymentMethodsByType(props.type, true))

    const hasSavedPaymentMethods = paymentMethods.value.length > 0

    const paymentMethodTitle = computed(() => sendMoneyStore.form.paymentMethodType?.text)
    const isLimitReached = ref(false)
    const isCorporateAccount = authStore.isCorporateAccount
    const paymentMethodType = sendMoneyStore.form.paymentMethodType?.value

    onBeforeMount(async () => {
      await cardHelper.retrieveCardSettings()
    })

    const buttonTitle = computed(() => {
      const paymentType = sendMoneyStore.form.paymentMethodType?.value

      switch (paymentType) {
        case PAYMENT_METHODS.DEBIT_CARD:
          return $t('AppPaymentCardModal.AddDebitCardTitleText').value
        case PAYMENT_METHODS.CREDIT_CARD:
          return $t('AppPaymentCardModal.AddCreditCardNewTitleText').value
        case PAYMENT_METHODS.DIRECT_DEBIT:
          return $t('AppPaymentCardModal.AddDirectDebitTitleText').value
      }
    })

    const paymentMethodsExists = computed(() => {
      return paymentMethods.value.length > 0
    })

    const selectCard = () => {
      const limitReached = cardHelper.cardLimitReached.value

      if (
       (paymentMethodType === PAYMENT_METHODS.CREDIT_CARD ||
        paymentMethodType === PAYMENT_METHODS.DEBIT_CARD) && limitReached
      ){
        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYMENT_METHOD_ADD_ATTEMPT_LIMIT_REACHED,
          traits: {
            paymentMethod: props.type,
            location: SEGMENT_LOCATIONS.SEND_MONEY,
            accountType: isCorporateAccount ? ACCOUNT_TYPE.CORPORATE : ACCOUNT_TYPE.CONSUMER,
            email: authStore.user.customer.email,
          },
        })
        router.replace('/send-money/payment/card-limit-reached')
      } else {
        emit('addPaymentMethod')
      }
    }

    onBeforeMount(() => {
      if (paymentMethods.value.length > 0) {
        const totalCards = paymentMethods.value.filter((method) => method.isCard).length

        const validCards = paymentMethods.value.filter(
          (method) => method.cardV2Data?.cardState === 'Active'
        )?.length

        const invalidCards = paymentMethods.value.filter(
          (method) => method.cardV2Data?.cardState === 'Invalid'
        )?.length

        const expiredCards = paymentMethods.value.filter(
          (method) => method.isCard && method.isExpired
        ).length

        const totalDdccounts = paymentMethods.value.filter((method) => !method.isCard).length
        const verifiedDdAccounts = paymentMethods.value.filter(
          (method) => !method.isCard && method.verificationStatus === 'Verified'
        ).length
        const nonVerifedDdAccounts = paymentMethods.value.filter(
          (method) => !method.isCard && method.verificationStatus !== 'Verified'
        ).length

        analyticsStore.track({
          event: SEGMENT_EVENTS.SAVED_PAYMENT_METHODS,
          traits: {
            totalCards: totalCards,
            validCards: validCards,
            invalidCards: invalidCards,
            expiredCards: expiredCards,
            totalDdccounts: totalDdccounts,
            verifiedDdAccounts: verifiedDdAccounts,
            nonVerifedDdAccounts: nonVerifedDdAccounts,
            location: props.isSendMoney ? SEGMENT_LOCATIONS.SEND_MONEY : SEGMENT_LOCATIONS.ACCOUNT,
          },
        })
      }
    })

    return {
      paymentMethods,
      $t,
      paymentMethodsExists,
      paymentMethodTitle,
      hasSavedPaymentMethods,
      buttonTitle,
      isLimitReached,
      selectCard,
    }
  },
})
</script>

<style scoped>
.saved-payment-methods-title {
  @apply font-medium text-base !important;
  @apply leading-4 tracking-extrawide;
  @apply self-center;
  @apply mb-1 mt-5;
  color: #636e82 !important;
}
</style>
