<template>
  <AppCard class="upload-document-wrapper">
    <template #header>
      <XeStepperCardHeader
        :title="
          uploadId === 'proofOfAddress' || uploadId === 'certProofOfId'
            ? ''
            : $t('PageSendMoneyVerification.UploadDocumentText').value
        "
        analytics-name="xe-send-money-amount"
      >
      </XeStepperCardHeader>
    </template>

    <div
      v-if="uploadId === 'sourceOfFunds' || uploadId === 'sourceOfFundsCopy'"
      class="description"
    >
      <p>
        {{ $t('PageSendMoneyVerification.DescriptionQuestionText').value }}
      </p>
      <AppLink class="tipsButton" @click="onHelpDeskClick" target="_new">
        {{ $t('PageSendMoneyVerification.DocumentToUse').value }}
      </AppLink>
    </div>

    <AppInputDropdown
      v-if="options"
      v-model="optionValue"
      :label="$t('SendMoneyWalletDetails.SourceOfFundsLabel').value"
      selected-display-field="text"
      selected-value-field="value"
      :placeholder="$t('PageSendMoneyVerification.SelectSourceText').value"
      :options="options"
    />

    <template v-if="options && optionValue === 'OTH'">
      <AppInputText
        v-model="optionFreeTextValue"
        :placeholder="$t('PageSendMoneyVerification.PleaseSpecifyText').value"
      />
    </template>

    <div v-if="uploadId === 'sourceOfFundsCopy'" class="description">
      <AppAlert theme="yellow" class="card-warning mt-6">
        <IconNewWarningYellow class="warning-icon-style" />
        <span
          v-html="$t('UploadDocumentModal.CheckDocumentWarning').value"
          class="warning-txt"
        ></span>
      </AppAlert>
    </div>

    <div v-else-if="uploadId === 'certProofOfId'" class="description">
      <h2 class="title">
        {{ $t('PageSendMoneyVerification.CertProofOfId.Title').value }}
      </h2>
      <AppAlert theme="yellow" class="card-warning mt-6">
        <IconNewWarningYellow class="warning-icon-style" />
        <span
          v-html="$t('PageSendMoneyVerification.CertProofOfId.Warning').value"
          class="warning-txt"
        ></span>
      </AppAlert>

      <img src="@galileo/assets/images/passport_proof_of_id.svg" alt="" class="passport-img" />

      <ul class="rules-list">
        <li v-for="rule in certProofOfId.rules" v-html="rule.text" class="flex items-start"></li>
      </ul>
    </div>

    <div v-else-if="uploadId === 'proofOfAddress'" class="description">
      <h2 class="title">
        {{ $t('PageSendMoneyVerification.proofOfAddress.Title').value }}
      </h2>
      <ul class="rules-list">
        <li v-for="rule in proofOfAddress.rules" v-html="rule.text" class="flex items-start"></li>
      </ul>
    </div>

    <div v-else-if="uploadId === 'reasonForTransferProof'" class="description">
      <h4>{{ $t('PageSendMoneyVerification.ReasonForTransferTitle').value }}</h4>
      <p class="mt-1 text-secondary-text">
        {{ $t('PageSendMoneyVerification.ReasonForTransferText').value }}
      </p>
    </div>

    <XeSofUpload
      ref="xeFileUploadRef"
      :max-size="5"
      :acceptedFileTypes="['image/png', 'image/jpeg', 'application/pdf']"
      @uploaded="fileUploaded"
      @drop.prevent="fileUploaded"
      @cleared="fileCleared"
    />

    <template #footer>
      <AppCardFooter class="flex flex-col">
        <AppButton
          :loading="loading"
          :disabled="
            file == null ||
            (uploadId === 'sourceOfFundsCopy' &&
              (!optionValue || (optionValue === 'OTH' && !optionFreeTextValue)))
          "
          class="upload-button"
          @click="sendDocument"
        >
          {{ $t('PageSendMoneyVerification.ButtonContinueText').value }}
        </AppButton>
        <AppLink
          v-if="uploadId === 'certProofOfId'"
          class="infoButton mt-7 order-1"
          @click="onLearnMoreClick"
        >
          {{ $t('PendingApprovalModal.LearnMoreButton').value }}
        </AppLink>
      </AppCardFooter>
    </template>
  </AppCard>
</template>

<script>
import XeFileUpload from '@galileo/components/XeFileUpload/XeFileUpload'
import XeSofUpload from '@galileo/components/XeFileUpload/XeSofUpload'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'
import { usePromiseLazy } from 'vue-composable'

import { onBeforeMount, ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import { IconNewWarningYellow } from '@galileo/assets/icons/vue'

import {
  AppCard,
  AppCardFooter,
  AppInputDropdown,
  AppButton,
  useVModel,
  AppInputText,
  AppLink,
  AppAlert,
} from '@oen.web.vue2/ui'

import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

import {
  useI18nStore,
  useSendMoneyStore,
  useAppStore,
  useResourcesStore,
  useAnalyticsStore,
} from '@galileo/stores'

export default {
  name: 'SendMoneyUploadDocument',
  components: {
    AppCard,
    AppCardFooter,
    XeStepperCardHeader,
    AppInputDropdown,
    AppButton,
    AppInputText,
    XeFileUpload,
    XeSofUpload,
    AppLink,
    AppAlert,
    IconNewWarningYellow,
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const { model } = useVModel(props, emit)
    const router = useRouter()
    const loading = ref(false)
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()

    const options = ref(null)
    const optionId = ref(null)
    const optionFreeTextId = ref(null)

    const xeFileUploadRef = ref()

    const resourcesStore = useResourcesStore()

    let uploadId = computed(() => {
      const uploadType = sendMoneyStore.form.uploadDocId

      if (uploadType === 'sourceOfFundsCopy') {
        options.value = resourcesStore.getSourceOfFunds
        optionId.value = 'sourceOfFunds'
        optionFreeTextId.value = 'sourceOfFundsFreeText'
      } else {
        options.value = null
        optionId.value = uploadType
        optionFreeTextId.value = null
      }

      if (xeFileUploadRef.value) {
        xeFileUploadRef.value.clearInput()
      }

      return uploadType
    })

    const optionValue = ref('')
    const optionFreeTextValue = ref('')
    const file = ref(null)

    const fileUploaded = (uploaded) => {
      file.value = uploaded
    }
    const fileCleared = () => {
      file.value = null
    }

    onBeforeMount(async () => {
      sendMoneyStore.setActiveStep('/send-money/summary/verification')
    })

    const processOrderSteps = reactive(
      usePromiseLazy(() => sendMoneyStore.processOrderSteps(sendMoneyStore.form.transferId))
    )

    const sendDocument = async () => {
      loading.value = true

      try {
        if (optionId.value) {
          sendMoneyStore.setFilledAdditionalField({
            field: optionId.value,
            value: optionValue.value,
          })
        }
        if (optionFreeTextId.value) {
          let freeText = ''
          if (optionValue.value === 'OTH') {
            freeText = optionFreeTextValue.value
          }
          sendMoneyStore.setFilledAdditionalField({
            field: optionFreeTextId.value,
            value: freeText,
          })
        }

        let documentUpload = await sendMoneyStore.uploadDocument(file.value)

        // events
        let trackEvent = null
        if (uploadId.value === 'reasonForTransferProof') {
          trackEvent = {
            event: SEGMENT_EVENTS.EDD_TRANSFER_REASON_PROOF_COMPLETED,
            traits: {},
          }
        } else if (uploadId.value === 'sourceOfFundsCopy') {
          trackEvent = {
            event: SEGMENT_EVENTS.EDD_SOURCE_OF_FUNDS_COMPLETED,
            traits: {
              sourceOfFundLog: {
                dropdown: optionFreeTextId.value ? true : false,
                uploadDocument: true,
              },
            },
          }
        } else if (uploadId.value === 'sourceOfFunds') {
          trackEvent = {
            event: SEGMENT_EVENTS.EDD_SOURCE_OF_FUNDS_COMPLETED,
            traits: {
              sourceOfFundLog: {
                dropdown: optionFreeTextId.value ? true : false,
                uploadDocument: false,
              },
            },
          }
        } else if (uploadId.value === 'proofOfAddress') {
          sendMoneyStore.setFilledAdditionalField({
            field: uploadId.value,
            value: documentUpload.data.data.key,
          })

          trackEvent = {
            event: SEGMENT_EVENTS.EDD_PROOF_OF_ADDRESS_COMPLETED,
            traits: {},
          }
        } else if (uploadId.value === 'certProofOfId') {
          sendMoneyStore.setFilledAdditionalField({
            field: uploadId.value,
            value: documentUpload.data.data.key,
          })
          trackEvent = {
            event: SEGMENT_EVENTS.EDD_CERTIFIED_PROOF_OF_IDENTITY_COMPLETED,
            traits: {},
          }
        }

        if (trackEvent) {
          analyticsStore.track(trackEvent)
        }

        // only if no errors
        await processOrderSteps.exec()

        if (processOrderSteps.error?.value) {
          // TODO: is .value needed?
          appStore.logException('Exception during confirm order', processOrderSteps.error)
          appStore.messageBoxGenericError()
        }

        sendMoneyStore.resetAdditionalFields()

        const nextPage = processOrderSteps?.result || processOrderSteps.error

        if (nextPage !== '/send-money/summary/upload-document') {
          router.replace(nextPage)
        }
      } catch (ex) {
        appStore.logException('Exception during sending document', ex)
        appStore.messageBoxGenericError()
      }
      loading.value = false
    }

    const onHelpDeskClick = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.WHAT_DOCUMENTS_MIGHT_I_NEED_FOR_A_LARGE_TRANSFER,
        article: HelpDesk[HelpDeskProperties.WHAT_DOCUMENTS_MIGHT_I_NEED_FOR_A_LARGE_TRANSFER],
      })
    }

    const onLearnMoreClick = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.HOW_DO_I_COMPLETE_VERIFICATION,
      })
    }

    const proofOfAddress = computed(() => {
      return {
        rules: [
          {
            text: $t('PageSendMoneyVerification.proofOfAddress.Rule1').value,
          },
          {
            text: $t('PageSendMoneyVerification.proofOfAddress.Rule2').value,
          },
          {
            text: $t('PageSendMoneyVerification.proofOfAddress.Rule3').value,
          },
          {
            text: $t('PageSendMoneyVerification.proofOfAddress.Rule4').value,
          },
        ],
      }
    })

    const certProofOfId = computed(() => {
      return {
        rules: [
          {
            text: $t('PageSendMoneyVerification.certProofOfId.NewRule2').value,
          },
          {
            text: $t('PageSendMoneyVerification.certProofOfId.Rule1').value,
          },
        ],
      }
    })

    return {
      $t,
      model,
      options,
      optionValue,
      optionFreeTextValue,
      fileUploaded,
      fileCleared,
      sendDocument,
      loading,
      file,
      uploadId,
      xeFileUploadRef,
      onHelpDeskClick,
      onLearnMoreClick,
      certProofOfId,
      proofOfAddress,
    }
  },
}
</script>

<style scoped>
/* Styles for proofOfAddress & certifiedProofOfId - start */
.upload-document-wrapper {
  ::v-deep b {
    font-weight: 600;
  }
  .title {
    @apply font-semibold mt-6;
    font-size: 24px;
    line-height: 34px;
  }

  ::v-deep header {
    .card-header-right {
      @apply invisible;
    }
  }

  ::v-deep .card-content .card-content-block {
    @apply mb-6;
  }

  .warning-txt {
    @apply ml-3 text-sm leading-5;
    color: #393939;
  }

  .rules-list {
    @apply mt-6;
    li {
      @apply relative tracking-normal text-gray-text block;
      padding-left: 18px;
      line-height: 24px;
      &::before {
        @apply absolute;
        font-size: 19px;
        content: '•';
        color: #8d8d8d;
        left: 2px;
        top: -1.5px;
      }

      &:not(:first-child) {
        @apply mt-3;
      }
    }
  }

  ::v-deep footer {
    &.card-footer {
      @apply flex flex-col;

      .infoButton.link {
        @apply font-medium text-base leading-6;
        color: var(--theme-color-primary, theme('colors.blue.default'));
      }
    }
  }
}
/* Styles for proofOfAddress & certifiedProofOfId - end */

.card-header {
  @apply pr-12;
}
.description h2 {
  @apply font-semibold text-base;
}
.description p {
  @apply font-normal text-sm;
}
.description {
  @apply mb-6;
}

.validation {
  @apply flex justify-between rounded-lg mb-7 py-2 px-3;
  background-color: #ffe1e6;
}

.validation p {
  @apply text-sm;
  color: #cd3d64;
}
.circle {
  @apply rounded-full border border-solid;
  width: 21px;
  height: 21px;
  border-color: #cd3d64;
}
.tipsButton.link {
  @apply text-sm font-medium;
  color: var(--theme-color-primary, theme('colors.blue.default'));
}
.warning-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
}
.warning-msg {
  background: rgba(243, 244, 246, 1);
  padding: 8px 12px 8px 12px;
  font-size: 14px;
  margin-bottom: 24px;
  border-radius: 5px;
}

::v-deep .card-content .card-content-block {
  @apply mb-8;

  .upload-successful .card-content-block {
    @apply mb-0;
  }
}

::v-deep .card-footer {
  .button.upload-button {
    @apply w-full;
  }
}

.passport-img {
  margin-top: 24px;
  width: 150%;
}
.warning-icon-style {
  flex: none;
  align-self: flex-start;
}
</style>
