<template>
  <AppDialog v-model="model"
    class="new-look-modal"
    icon="hourglass">
    <template #header>
      <div>
        <XeBackButton
          class="closeButton"
          icon="x"
          @click="closeModal(true)"
        />
      </div>
      <AppDialogHeader>
        <IconHourglass class="hourglass" />
        <div class="header">
          {{ $t('LimitReachedModal.Title').value }}
        </div>
      </AppDialogHeader>
    </template>
    <div class="info">
      {{ $t('LimitReachedModal.ParagraphText').value }}
    </div>
    <template #footer>
      <AppDialogFooter class="dialogFooter">
        <AppButton @click="closeModal(false)">
          {{ $t('LimitReachedModal.ChangePaymentMethodButton').value }}
        </AppButton>
        <AppButton theme="secondary" @click="deleteCard">
          {{ $t('LimitReachedModal.RemoveCard').value }}
        </AppButton>
      </AppDialogFooter>
    </template>
  </AppDialog>
</template>

<script>
import { defineComponent, computed, ref } from '@vue/composition-api'
import { AppDialog, AppButton, useVModel, AppDialogHeader, AppDialogFooter } from '@oen.web.vue2/ui'

import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { useRouter } from '@galileo/composables/useRouter'

import { useAnalyticsStore, useI18nStore, useSendMoneyStore, useAuthStore, useActivityStore } from '@galileo/stores'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import useCardHelper from '@galileo/composables/useCardHelper/index.ts'

import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import {
  IconHourglass,
} from '@galileo/assets/icons/vue'

export default defineComponent({
  name: 'LimitReachedModal',
  components: {
    AppDialog,
    AppButton,
    InformationAppModal,
    XeBackButton,
    IconHourglass,
    AppDialogHeader,
    AppDialogFooter
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
   setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const router = useRouter()
    const sendMoneyStore = useSendMoneyStore()
    const analyticsStore = useAnalyticsStore()
    const activityStore = useActivityStore()
    const cardHelper = useCardHelper()

    let shouldDisplayModal = computed(
      () => cardHelper.cardLimitReached.value
    )

    const deleteCard = () => {
       router.replace('/payment-methods')
    }

    const closeModal = (isClose) => {
      if(!isClose){
        sendMoneyStore.isPaymentMethodTypeSelected = false
      }
      router.replace('/send-money/payment')
    }

    return {
      $t,
      model,
      deleteCard,
      closeModal
    }
  },
})
</script>

<style scoped>
.closeButton {
  position: relative;
  left: 83%;
  margin-top: 44px;
}
.hourglass {
  height: 38.5px;
  width: 42px;
  margin-bottom: 40px;
  display: inline;
}

.dialogHeader {
  margin-top: 0rem;
}
.dialogFooter {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
}
.header {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}
.info {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
::v-deep .button--secondary{
  width: inherit !important;
  margin-top: 24px;
}
::v-deep .card{
  width: 440px;
}
::v-deep .card-content{
  padding-bottom: 32px !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
</style>
