var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppCard',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('XeStepperCardHeader',{attrs:{"title":_vm.bankProviderTitle,"emitBack":true,"analytics-name":"xe-send-money-payment"},on:{"back":function($event){return _vm.$emit('backToPaymentType', $event)}}})]},proxy:true},{key:"footer",fn:function(){return [_c('AppCardFooter',[_c('AppButton',{staticClass:"bankTransfer-footer",attrs:{"disabled":_vm.validation.$anyInvalid,"analytics-name":"xe-send-money-payment-continue"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('SendMoneyPaymentBankTransfer.ContinueButtonText').value)+" ")])],1)]},proxy:true}])},[_c('AppAlert',{staticClass:"mb-6",attrs:{"theme":"blue"}},[_c('p',{staticClass:"flex"},[_c('IconExclamationMark',{staticClass:"mr-2 w-12"}),_vm._v(" "+_vm._s(_vm.$t('SendMoneyPaymentBankTransfer.BankDetailsAlert').value)+" ")],1)]),_c('p',{staticClass:"bank-transfer-paragraph",domProps:{"innerHTML":_vm._s(
      _vm.$t('SendMoneyPaymentBankTransfer.BankProviderBankTrasnferParagraph', {
        currencyFrom: _vm.currencyFrom,
        currencyTo: _vm.currencyTo,
      }).value
    )}}),_c('AppInputDropdown',{staticClass:"label-fix",attrs:{"placeholder":_vm.$t('SendMoneyPaymentBankTransfer.DropdownPlaceholderText').value,"label":_vm.$t('SendMoneyPaymentBankTransfer.TransferToOurAccountLabel').value,"options":_vm.bankProviders,"helper-text":_vm.$t('SendMoneyPaymentBankTransfer.AccountInfoHelp', {
        currencyFrom: _vm.currencyFrom,
      }).value,"analytics-name":"xe-send-money-payment-bank-provider","selected-display-field":"name","selected-value-field":"id"},model:{value:(_vm.form.bankProvider),callback:function ($$v) {_vm.$set(_vm.form, "bankProvider", $$v)},expression:"form.bankProvider"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }