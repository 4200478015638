<template>
  <AppCard>
    <template #header>
      <XeStepperCardHeader
        :title="$t('PageSendMoneyAddPaymentMethodType.Header').value"
        analytics-name="xe-send-money-add-payment-method"
        :emit-back="true"
        @back="() => goBack()"
      />
    </template>

    <AddPaymentMethodType
      :payment-method-type="paymentMethodType"
      :payment-methods-option="paymentMethodsList"
      @select="$emit('selectPaymentMethodType', $event)"
    />
    <!-- @select="selectPaymentMethodType($event)" -->
  </AppCard>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'
import AddPaymentMethodType from '@galileo/components/Views/PaymentMethod/AddPaymentMethodType'

import { useRouter } from '@galileo/composables/useRouter'

import { AppCard } from '@oen.web.vue2/ui'


import { useI18nStore, useAppStore, useSendMoneyStore } from '@galileo/stores'



export default defineComponent({
  components: {
    AppCard,
    XeStepperCardHeader,
    AddPaymentMethodType,
  },
  props: {},
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()

    const paymentMethodType = computed(() => sendMoneyStore.form.paymentMethod)
    const paymentMethodsList = computed(() => sendMoneyStore.form.paymentMethods)

    const goBack = () => {
      const acceptedBackPaths = ['SendMoneyRecipient', 'SendMoneyAmount', 'SendMoneyDelivery']
      // const isFromRecipient = store.getters['sendMoney/isFromRecipient']
      const currentRoute = appStore.getCurrentRoute

      if (acceptedBackPaths.includes(currentRoute.from.name)) {
        router.go(-1)
      } else {
        router.goBackTo({ name: 'SendMoneyRecipient' }, true)
      }
    }

    return { goBack, paymentMethodType, paymentMethodsList, $t }
  },
})
</script>
