<template>
  <information-app-modal :value="value" @close="closeModal" icon="altInfo" shouldDisplayCloseButton>
    <h1 class="title">{{ $t('RedirectToQuickTransfer.Title').value }}</h1>
    <p class="modal-main-text">{{ $t('RedirectToQuickTransfer.MainText').value }}</p>

    <template #footer>
      <AppButton @click="goToQuickTransfer">
        {{ $t('RedirectToQuickTransfer.ConfirmButton').value }}
      </AppButton>
      <AppButton theme="text-secondary" @click="closeModal" class="cancel-button">
        {{ $t('RedirectToQuickTransfer.CancelButton').value }}
      </AppButton>
    </template>
  </information-app-modal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { useI18nStore } from '@galileo/stores'
import { useRouter } from '@galileo/composables/useRouter'
import { AppButton, useVModel } from '@oen.web.vue2/ui'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
    },
  },
  components: {
    AppButton,
    InformationAppModal,
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const router = useRouter()
    const { model } = useVModel(props, emit)

    const goToQuickTransfer = () => {
      router.push('/one-step-transfer')
    }

    const closeModal = () => {
      model.value = false
    }

    return { $t, closeModal, goToQuickTransfer }
  },
})
</script>

<style scoped>
::v-deep .card-content {
  .card-content-block {
    @apply mb-3;
  }
  .title {
    @apply font-semibold;
  }
  .modal-main-text {
    @apply text-grayblue-400 text-sm mt-1;
  }
}
::v-deep .card .card-header {
  @apply p-12 pb-5;
  .card-header-title {
    @apply mt-8 mb-2;
    height: fit-content;
  }
  .card-header-right {
    @apply mb-auto;
  }
}
::v-deep .card-footer .cancel-button {
  @apply mt-2;
  .button-inner {
    @apply text-grayblue-300 font-semibold;
  }
}
</style>
