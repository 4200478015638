<template>
  <div>
    <AppListItemTitle
      v-if="paymentMethod.value === 'Interac'"
      class="interac-wrapper payment-method-item-title-container"
    >
      <p>
        {{ paymentMethod.text }}
      </p>
      <IconInterac />
    </AppListItemTitle>
    <AppListItemTitle v-else class="payment-method-item-title-container">
      {{ paymentMethod.text }}
    </AppListItemTitle>
    <template
      v-if="
        !paymentMethod.isAvailable &&
        paymentMethod.value === 'BankTransfer' &&
        selectedDeliveryMethod === 'CashPayout'
      "
    >
      <AppListItemCaption class="payment-method-item-caption">
        {{ $t('PageSendMoneyAmount.CashPickupNotAvailable').value }}
      </AppListItemCaption>
    </template>
    <template
      v-else-if="
        !paymentMethod.isAvailable &&
        paymentMethod.value === 'BankTransfer' &&
        selectedDeliveryMethod === 'MobileWallet'
      "
    >
      <AppListItemCaption class="payment-method-item-caption">
        {{ $t('PageSendMoneyAmount.MobileWalletNotAvailable').value }}
      </AppListItemCaption>
    </template>
    <template
      v-else-if="
        paymentMethod.unavailableReason &&
        paymentMethod.unavailableReason.code &&
        !paymentMethod.isAvailable
      "
    >
      <template
        v-if="
          paymentMethod.unavailableReason.code === 'MaxDirectDebitAmountPerDayExceeded' ||
          paymentMethod.unavailableReason.code === 'MaxDirectDebitTradesPerDayExceeded' ||
          paymentMethod.unavailableReason.code === 'MaxFailedCardAuthorizationAttemptsExceeded'
        "
      >
        <AppListItemCaption
          v-if="paymentMethod.value === 'DirectDebit'"
          class="payment-method-item-caption"
        >
          {{ $t('QuoteErrors.MaxDirectDebitPerDayExceeded').value }}
        </AppListItemCaption>

        <AppListItemCaption
          v-else-if="paymentMethod.value === 'CreditCard' || paymentMethod.value === 'DebitCard'"
          class="payment-method-item-caption"
        >
          {{ $t('QuoteErrors.MaxFailedCardAuthorization').value }}
        </AppListItemCaption>

        <AppListItemCaption
          v-else-if="paymentMethod.value === 'BankTransfer'"
          class="payment-method-item-caption"
        >
          {{ $t('QuoteErrors.MaxFailedCardAuthorization').value }}
        </AppListItemCaption>
      </template>
      <!-- Get Error from the quote -->
      <AppListItemCaption v-else class="payment-method-item-caption">
        <p v-html="paymentMethod.quote.errorText" />
      </AppListItemCaption>
    </template>
    <!-- Fallback to generic error message if is not included -->
    <template v-else-if="!paymentMethod.isAvailable">
      <AppListItemCaption class="payment-method-item-caption">
        {{ $t('PageSendMoneyAmount.GenericNotAvailable').value }}
      </AppListItemCaption>
    </template>

    <AppListItemCaption v-if="paymentMethod.isAvailable" class="payment-method-item-caption--gray">
      {{ getFee(paymentMethod) }}
    </AppListItemCaption>
    <BankLogos v-if="useBankLogos(paymentMethod.value)" />
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { AppListItemTitle, AppListItemCaption } from '@oen.web.vue2/ui'
import { useI18nStore, useSendMoneyStore } from '@galileo/stores'
import { useBankLogos } from '@galileo/composables/useBankLogos'

import { IconInterac } from '@galileo/assets/icons/vue'
import BankLogos from '@galileo/components/Views/SendMoney/SendMoneyBankLogos'

export default {
  name: 'PaymentMethodsListItemCaption',
  components: {
    AppListItemTitle,
    AppListItemCaption,
    IconInterac,
    BankLogos,
  },
  props: {
    paymentMethod: {
      type: Object,
      default: null,
    },
  },
  emits: [],
  setup(props) {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()

    const currencyFrom = computed(() => sendMoneyStore.getCurrencyFrom)
    const selectedDeliveryMethod = ref(sendMoneyStore.form.deliveryMethod)

    const getFee = (method) => {
      const totalFee = method.quote.paymentMethodFee + method.quote.transferFee
      return `+${totalFee.toFixed(2)} ${currencyFrom.value} - ${method.quote.time}`
    }

    return {
      $t,
      currencyFrom,
      getFee,
      selectedDeliveryMethod,
      useBankLogos,
    }
  },
}
</script>

<style scoped>
.list-item {
  @apply border-none;
}

.list-item-title {
  @apply text-base font-medium;
}

.list-item-caption {
  @apply font-medium text-xs;
}

.interac-wrapper {
  @apply flex items-center;
  svg {
    @apply ml-2;
  }
}

.xe-caption {
  @apply font-semibold text-gray-text;
  font-size: 10px;
  letter-spacing: 1px;
}

.payment-method-item--is-disabled {
  @apply cursor-not-allowed pointer-events-none;
  .payment-method-item-title-container {
    @apply text-tertiary-text;
    svg {
      @apply opacity-50;
    }
  }
  .payment-method-item-caption {
    @apply text-red-text;
    ::v-deep .contact-link {
      @apply underline;
    }
  }
  .payment-method-item-caption--gray {
    @apply text-tertiary-text;
  }
  ::v-deep .list-item-action {
    @apply text-tertiary-text;
  }
}

::v-deep .list-item-caption {
  @apply text-xs font-normal text-tertiary-text;
}
</style>
