<template>
  <InformationAppModal
    :value="model"
    :icon="ICON_TYPE.ICON_INFO_ALT"
    should-display-close-button
    @close="$emit('close')"
  >
    <template #default>
      <h1 class="title">
        {{ $t('UpdateLegacyCardModal.TitleText').value }}
      </h1>
      <p>
        {{ $t('UpdateLegacyCardModal.ContentText').value }}
      </p>
    </template>
    <template #footer>
      <AppButton @click="$emit('update')" theme="primary">
        {{ $t('UpdateLegacyCardModal.ButtonText').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal.vue'
import { defineComponent } from '@vue/composition-api'

import { AppButton, useVModel } from '@oen.web.vue2/ui'
import { useI18nStore } from '@galileo/stores'

export default defineComponent({
  components: { InformationAppModal, AppButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)
    const { $t } = useI18nStore()
    return { ICON_TYPE, model, $t }
  },
})
</script>

<style scoped>
::v-deep .card-header {
  @apply px-12;
}

::v-deep .card-header .card-header-title {
  height: fit-content;
  svg {
    @apply mt-8 max-h-20;
    max-width: 5rem;
  }
}
</style>
